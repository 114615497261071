<template>
  <div>
<Button data-toggle="modal"
      data-target="#BCProductsModal"  btnVariant="yellow"> Import BigCommerce </Button>
<!-- Modal -->
<div class="modal  fade"  id="BCProductsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="BCProductsModalRef">
  <div class="modal-dialog modal-xl ">
    <div class="modal-content  p-4" >

       
      <div  class="container p-2 pb-4 " >

         <div v-if="isAllProductLoading">
            <LoaderComp/>
         </div>

            <div v-else  class="">
                
                     <div class="row  topSection">
           <div class="d-flex col-12  justify-content-between  align-items-center ShopifyTopSection">
              <p class="TitleHead mt-3  text-center">Select Products <span class="" v-if="Products.length" style="font-size:13px;"> (Total {{Products.length}} Products loaded)</span></p>
               <div style="position: absolute; bottom: 0; left: 35px; right: 0; width: 93.5%; height: 1px; background-color: #dee2e6;"></div>
               
          </div>
         
          </div>
         

         <div class="row mt-2 " style=" min-height:400px;">
            <div class="col-12 d-flex justify-content-between  align-items-center h-100 searchSection">
                <b-form-input class="w-50" placeholder="Search Products" v-model="searchProducts"></b-form-input>

                    <div class="">
                        <p class="m-0  selectedPara">{{selectedProducts.length}} selected</p>
                    </div>
            </div>
            <div class="col-12   ShopifyProductDiv">

                <div class="" v-if="isAllProductLoading">
                   <LoaderComp/>
                </div>
                <div class="ShopifySelectTable" v-else>
                <vue-bootstrap4-table
                ref="ShopifyProductTable"
                class="DataTable"
                :rows="getProductsTable"
                :columns="columns"
                :config="config"
                @on-select-row="OnRowCheckBoxChanged($event)"
                @on-all-select-rows="
                OnRowCheckBoxChanged($event)
                "
                @on-unselect-row="OnRowCheckBoxChanged($event)"
                @on-all-unselect-rows="
                OnRowCheckBoxChanged($event)
                "
                >
                <template slot="Image" slot-scope="props">
                    <LazyImage :src="getImg(props.row.image)"  :key="`${props.row.title}${props.row.title}`" style="height:50px;width:50px;border-radius:50%;cursor:pointer;" alt="" />
                </template>
                <template slot="Title" slot-scope="props" style="text-align:left !important">
                    <p class="ProductTitle" style="cursor:pointer;" >{{props.row.title}}</p>
                </template>
                </vue-bootstrap4-table>
                </div>
            </div>
           
           <div class="col-12 d-flex justify-content-end align-items-center ShopifyBottomSection pt-3">
              <div style="position: absolute; top: 0; left: 35px; right: 0; width: 93.5%; height: 1px; background-color: #dee2e6;"></div>
            <div class="d-flex align-items-center">
                <button class="btn px-3 mr-4 py-2 text-white" @click="closeModal" style="background:#B3AFB6;border-radius:6px; ">Cancel</button>
                 <button :disabled="!selectedProducts.length" class="btn px-3 py-2 text-white" style="background:rgba(77, 27, 126, 0.8);border-radius:6px; " @click="saveProducts">Save Product</button>
                 </div>
           
         </div>
         </div>

      
            </div>
         

        
       </div>

    </div>
  </div>
</div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import axios from "axios"
import LoaderComp from "../../../customize/component/LoaderComp.vue";
import VueBootstrap4Table from "vue-bootstrap4-table";
import {mapGetters} from "vuex";
import $ from 'jquery'
import Button from '../../Layout/Button.vue';
export default {
    components:{
        VueBootstrap4Table,
        LoaderComp,
        Button
    },
    props:["dbProducts"],
data(){
    return{
        isError:false,
     isAllProductLoading:false,
       searchProducts:'',
        config: {
                checkbox_rows: true,
                rows_selectable: false,
                pagination: false,
                pagination_info: false,
                num_of_visibile_pagination_buttons: 5,
                per_page: 10,
                per_page_options: [5, 10, 15, 20, 30],
                show_refresh_button: false,
                show_reset_button: false,
                card_mode: true,
                card_title: "",
                highlight_row_hover_color: "#f2f2f2",

                global_search: {
                    placeholder: "Enter custom Search text",
                    visibility: false,
                    case_sensitive: false,
                    showClearButton: false,
                    searchOnPressEnter: false,
                    searchDebounceRate: 1000
                }
            },
        columns: [
                {
                    label: "Image",
                    name: "image",
                    slot_name: "Image"
                },
                {
                    label: "Title",
                    name: "title",
                    slot_name: "Title"
                },
            ],
           Products:[],
            selectedProducts: [],
    }
},
    methods:{
        getImg(img){
            if(img) {
                return img
            } else {
                return 'https://images.quizell.com/default/default_image.png'
            }
        },
        resetModal(){
            this.isError = false
            this.searchProducts = ""
            this.selectedProducts = []
            this.Products=[]
        },
        OpenModal(){
            this.resetModal()
            this.getAllProducts()
        $('#BCProductsModal').modal('show')
    },
        closeModal(){
            
            $('#BCProductsModal').modal('hide')
        },
        // getSelectedProductsIDArray(items){
        //     let selectedIds = items.map(item => {
        //         return {
        //             id:item.id
        //         }
        //     })

        //     return selectedIds
        // },
        
        ImageSrc(img){

            if(img.edges.length) return img.edges[0].node.src
            return "/images/default_start_page.png"
        },
      OnRowCheckBoxChanged(e) {
          this.selectedProducts = e.selected_items  ;
        },
          debounceInput: debounce(function () {
            this.getAllProducts()
  }, 1000),

  async getAllProducts(){
  
             this.isAllProductLoading = true
       try {
         let data = {
            keyword:this.searchProducts ,
            per_page:50,
            page:null ,
        };
          const response = await axios.get(`/api/bigcommerce/products`, { params: data });
          if(response.status == 200 ){
              this.Products =  response.data.products;  
            //   this.Products = this.filterProducts(response.data.products);
               
                        }
                      
                    } catch (error) {
                        if(error){
                        this.$toasted.show("Error occured", {
                    theme: "toasted-primary",
                    type: "error",
                    position: "bottom-center",
                    duration : 2000,

                });
      }
      throw error;
      }  finally{
           this.isAllProductLoading = false
      }
        },

        async saveProducts(){
             this.isAllProductLoading = true
             try {
         let data = {
           ids : this.getSelectedProductsIDArray ,
        };
        const response = await axios.post(`/api/bigcommerce/products`, data);
          if(response.status == 200 ){
            
              this.closeModal()
              this.resetModal()
              this.$emit("callDBProducts")
            
                        }
                    } catch (error) {
                        if(error){
                        this.$toasted.show("Error occured", {
                    theme: "toasted-primary",
                    position: "bottom-center",
                    duration : 2000,

                });
      }
      throw error;
      }  finally{
           this.isAllProductLoading = false
      }
        },
        filterProducts(arr){
             let result = arr.filter(obj1 => this.dbProducts.some(obj2=> obj1.shopify_id == obj2.shopify_id))

             const finalArr = arr.filter( ( el ) => !result.includes( el ) );
             if(finalArr.length){

                if(this.searchProducts !== ''){
                    const frontFiltered = finalArr.filter((obj)=> obj.title.toLowerCase().includes(this.searchProducts.toLowerCase()))
                    return frontFiltered
                }
                else {
                    return finalArr
                }
             }
             else{
                return finalArr
             }
            // return finalArr
        }
    },
    computed:{
         ...mapGetters( ["GetTokenFromMetaTag"]),
          getProductsTable() {
            return this.Products
        },
          getSelectedProductsIDArray(){
            if(this.selectedProducts.length){
                 let selectedIds = this.selectedProducts.map(item => {
                return item.id
            })

            return selectedIds
            } 
            else {
                return null
            }
           
        },
        // compareProducts(){
        //     let result = this.Products.filter(obj1 => this.dbProducts.some(obj2=> obj1.node.id == obj2.shopify_id))
        //     if(result.length) return result

        //     return []
        // }
    },
    watch:{
        searchProducts(){
            if(this.searchProducts !== ''){
                this.debounceInput();
            } else {
                this.getAllProducts()
            }
        }
    },
    mounted(){
     $(this.$refs.BCProductsModalRef).on("show.bs.modal", this.OpenModal);
     $(this.$refs.sortProduct).on("hidden.bs.modal", this.closeModal);
}
}
</script>

<style scoped>
.modal-btn {
  background: var(--secondary-color);
  color: #ffffff;
  border: none;
}
.modal-btn:hover {
  color: #ffffff;
}

.modal-content
{
    background: #FFFFFF;
box-shadow: 0px 4px 16px rgba(77, 27, 126, 0.1);
border-radius: 20px;
}
.modal-content .container
{
min-height:50vh;
}

.ShopifyTopSection{
   /* border-bottom:2px solid #dee2e6; */
   padding: 0 10px 0px 34px;
    position: relative;

}
.searchSection{
     padding: 0 40px 10px 34px;
}
.ShopifyBottomSection{
   /* border-top:2px solid #dee2e6; */
   position: relative;
    padding: 0 40px 0 34px;
}

.TitleHead{
   font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 33px;
color: #161E34;
}


 /* Product Data Table Styles  */
 .ShopifyProductDiv{
    min-height:400px;
 }
.ShopifySelectTable {
    max-height:500px;
    overflow:hidden scroll;
    scroll-behavior: smooth;
}

.ShopifySelectTable .DataTable.card {
    border: none;
}
.ShopifySelectTable .DataTable >>> tbody tr td {
  vertical-align: middle;
}
.ShopifySelectTable >>> .card-header {
    display: none !important;
}
.ShopifySelectTable >>> .card-footer {
    background: transparent;
    padding: 0 !important;
    border: none;
}

.ShopifySelectTable >>> .vbt-per-page-dropdown  .btn-primary {
    background: rgba(77, 27, 126, 0.8) !important;
    border: none !important;
    color: #ffffff !important;
}
.ShopifySelectTable >>> .vbt-per-page-dropdown .dropdown-item.active {
    background: rgba(77, 27, 126, 0.8) !important;
    text-decoration: none !important;
    color: #ffffff !important;
}

.selectedPara{
    border:1px solid #ced4da;
    padding:6px 20px;
    border-radius:4px;
}

</style>